<template>
  <div class="preview">
    <div class="title">
      <ul>
        <li class="left-head" @click="goback">
          <i class="iconfont icon-back" v-if="this.id"></i>
          <i v-else class="iconfont icon--"></i>
        </li>
        <li class="middle-head">
          {{ title }}
        </li>
        <li class="right-head" @click="handleShare">分享</li>
        <li class="menu-text morelist">
          <span @click="showMenuList = !showMenuList">更多</span>
          <div class="menu-list" v-show="showMenuList">
            <span @click="copyAll">复制</span>
            <span @click="download()">下载</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="content">
      <!-- 法律片段 -->
      <div class="law-content" v-if="type === 'LAW_FRAGMENT'">
        <div class="law-items" v-for="(item1, index1) in content" :key="index1">
          <div class="text">
            <span class="text-title" @click="changeShow(item1.showicon, index1)">{{ item1.name }} </span>
            <span class="showicon" v-show="item1.showicon">
              <i class="iconfont icon-fuzhi" @click="copyText(item1.name)"></i>
              &nbsp;&nbsp;
              <span @click="copyMore(item1)">
                <svg t="1611822387975" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="113028" width="16" height="16">
                  <path
                    d="M725.179728 157.115733H147.461683C68.090305 157.115733 3.487283 221.809778 3.487283 301.283556v578.548622C3.487283 959.305956 68.090305 1024 147.461683 1024H725.179728c79.371378 0 143.9744-64.694044 143.9744-144.167822V301.283556c0-79.485156-64.603022-144.1792-143.9744-144.1792z m79.371377 722.716445a78.836622 78.836622 0 0 1-79.371377 79.485155H147.461683a78.836622 78.836622 0 0 1-79.371378-79.485155V301.283556a78.836622 78.836622 0 0 1 79.371378-79.485156H725.179728a78.836622 78.836622 0 0 1 79.371377 79.485156z m0 0"
                    p-id="113029"
                  ></path>
                  <path
                    d="M878.438394 0H250.817417c-44.293689 0-86.744178 22.175289-114.437689 57.298489-11.070578 14.791111-7.384178 35.1232 7.384177 46.216533s35.066311 7.395556 46.136889-7.395555c14.791111-20.332089 36.909511-31.414044 62.759823-31.414045h627.564088c42.461867 0 77.5168 35.111822 77.5168 77.630578v628.440178c0 20.332089-9.227378 40.664178-23.995733 55.455289a34.224356 34.224356 0 0 0-1.831822 46.205155c3.6864 5.552356 12.925156 9.238756 22.152533 9.238756a34.019556 34.019556 0 0 0 22.152534-9.238756c27.682133-25.873067 44.293689-64.694044 44.293688-103.537778v-626.574222A142.279111 142.279111 0 0 0 878.438394 0z m0 0"
                    p-id="113030"
                  ></path>
                  <path
                    d="M679.042839 558.216533H468.622217V345.645511a33.223111 33.223111 0 1 0-66.446223 0V556.373333H191.755372a33.268622 33.268622 0 0 0 0 66.537245h210.420622v210.716444a33.223111 33.223111 0 1 0 66.446223 0V622.8992h210.420622a33.109333 33.109333 0 0 0 33.223111-33.268622c0-18.488889-14.791111-31.425422-33.223111-31.425422z m0 0"
                    p-id="113031"
                  ></path>
                </svg>
              </span>
              &nbsp;&nbsp;
              <i class="iconfont icon-daochu" v-show="item1.lawid" @click="jumpToDetail(item1.lawid)"></i>
            </span>
            <!-- <div class="checkbox" @click.stop="changechild(item1.flag, index1)">
              <el-checkBox v-model="item1.flag"></el-checkBox>
            </div> -->
          </div>
          <div class="children child-text" v-if="!item1.children && item1.text">
            {{ item1.text }}
          </div>
          <div class="children" v-for="(item2, index2) in item1.children" :key="index2">
            <div class="text">
              <div style="text-align: left">
                <span @click="changeShowChild(item2.showicon, index1, index2)">{{ item2.name || item2.title }}</span>
                <!-- <div class="checkbox" @click.stop="change(index1, index2)">
                  <el-checkBox v-model="item2.flag"></el-checkBox>
                </div> -->
                <span class="showicon" v-show="item2.showicon">
                  <i class="iconfont icon-fuzhi" @click="copyText(item2.title)"></i>
                  &nbsp;&nbsp;
                  <span @click="copyMore(item2)">
                    <svg t="1611822387975" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="113028" width="16" height="16">
                      <path
                        d="M725.179728 157.115733H147.461683C68.090305 157.115733 3.487283 221.809778 3.487283 301.283556v578.548622C3.487283 959.305956 68.090305 1024 147.461683 1024H725.179728c79.371378 0 143.9744-64.694044 143.9744-144.167822V301.283556c0-79.485156-64.603022-144.1792-143.9744-144.1792z m79.371377 722.716445a78.836622 78.836622 0 0 1-79.371377 79.485155H147.461683a78.836622 78.836622 0 0 1-79.371378-79.485155V301.283556a78.836622 78.836622 0 0 1 79.371378-79.485156H725.179728a78.836622 78.836622 0 0 1 79.371377 79.485156z m0 0"
                        p-id="113029"
                      ></path>
                      <path
                        d="M878.438394 0H250.817417c-44.293689 0-86.744178 22.175289-114.437689 57.298489-11.070578 14.791111-7.384178 35.1232 7.384177 46.216533s35.066311 7.395556 46.136889-7.395555c14.791111-20.332089 36.909511-31.414044 62.759823-31.414045h627.564088c42.461867 0 77.5168 35.111822 77.5168 77.630578v628.440178c0 20.332089-9.227378 40.664178-23.995733 55.455289a34.224356 34.224356 0 0 0-1.831822 46.205155c3.6864 5.552356 12.925156 9.238756 22.152533 9.238756a34.019556 34.019556 0 0 0 22.152534-9.238756c27.682133-25.873067 44.293689-64.694044 44.293688-103.537778v-626.574222A142.279111 142.279111 0 0 0 878.438394 0z m0 0"
                        p-id="113030"
                      ></path>
                      <path
                        d="M679.042839 558.216533H468.622217V345.645511a33.223111 33.223111 0 1 0-66.446223 0V556.373333H191.755372a33.268622 33.268622 0 0 0 0 66.537245h210.420622v210.716444a33.223111 33.223111 0 1 0 66.446223 0V622.8992h210.420622a33.109333 33.109333 0 0 0 33.223111-33.268622c0-18.488889-14.791111-31.425422-33.223111-31.425422z m0 0"
                        p-id="113031"
                      ></path>
                    </svg>
                  </span>
                  &nbsp;&nbsp;
                  <i class="iconfont icon-daochu" v-show="item1.lawid" @click="jumpToDetail(item2.lawid, item2.name)"></i>
                </span>
              </div>
              <div class="text-content">
                {{ item2.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--争议焦点-->
      <div class="LEGAL_PRECEDENT_FRAGMENT" v-else-if="type === 'LEGAL_PRECEDENT_FRAGMENT'">
        <div class="law-items" v-for="(item1, index1) in content" :key="index1">
          <div class="text" style="display: flex">
            <div style="width: 90%" @click="toDetail(item1.docid)">
              {{ item1.text }}
            </div>
            <!-- @click="ToDetail(item1.docid)" -->
            <div class="checkbox" @click.stop style="width: 5%; margin-left: 10px">
              <el-checkBox v-model="item1.flag"></el-checkBox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detail" v-if="detaillaw">
      <law-detail :id="lawid" :catlog="catlog" @changeflag="detaillaw = false"></law-detail>
    </div>
    <div class="detail" v-if="detailpre">
      <precedentDetail :id="docid" @changeflag="detailpre = false" />
    </div>
  </div>
</template>

<script>
import { caseBase } from '~api-config'
import longUrl2ShortUrl from '@/common/longUrl2ShortUrlUtils.js'
import elCheckBox from '@/pages/precedentDetail/tabs/checkBox'
export default {
  props: ['id', 'userId'],
  layout: 'empty',
  components: {
    elCheckBox,
    precedentDetail: () => import('@/pages/precedentDetail/index'),
    lawDetail: () => import('@/pages/Search/pages/lawDetail/index'),
  },
  data() {
    return {
      showMenuList: false,
      title: '',
      content: {},
      type: '',
      detaillaw: false,
      detailpre: false,
      docid: '',
      lawid: '',
      isPC: false,
    }
  },

  computed: {
    isIOS() {
      return navigator.userAgent === this.$ua.i
    },
    _browserRedirect() {
      if (navigator) {
        const sUserAgent = navigator.userAgent.toLowerCase()
        // 输出：sUserAgent mozilla/5.0 (iphone; cpu iphone os 9_1 like mac os x) applewebkit/601.1.46 (khtml, like gecko) version/9.0 mobile/13b143 safari/601.1
        const bIsIpad = sUserAgent.match(/ipad/i) || false
        const bIsIphoneOs = sUserAgent.match(/iphone/i) || false
        const bIsMidp = sUserAgent.match(/midp/i) || false
        const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) || false
        const bIsUc = sUserAgent.match(/ucweb/i) || false
        const bIsAndroid = sUserAgent.match(/android/i) || false
        const bIsCE = sUserAgent.match(/windows ce/i) || false
        const bIsWM = sUserAgent.match(/windows mobile/i) || false
        if (bIsIpad[0] || bIsIphoneOs[0] || bIsMidp[0] || bIsUc7[0] || bIsUc[0] || bIsAndroid[0] || bIsCE[0] || bIsWM[0]) {
          /* 当前是手机端 */
          // this.$message('当前是手机端')
          return false
        } else {
          /* 当前是PC端 */
          // this.$message('当前是PC端')
          return true
        }
      } else {
        // this.$message('当前是手机端')
        return false
      }
    },
  },
  methods: {
    goback() {
      if (this.id) {
        this.$emit('clearpl')
      } else {
        window.goback()
      }
    },
    changeShow(flag, index) {
      this.$set(this.content[index], 'showicon', !flag)
    },
    changeShowChild(flag, index1, index2) {
      if (this.content[index1] && this.content[index1].children.length) {
        this.$set(this.content[index1].children[index2], 'showicon', !flag)
      }
    },
    changechild(flag, index) {
      if (flag) {
        this.content[index].children.forEach((item) => {
          this.$set(item, 'flag', true)
        })
      } else {
        const Index = this.content[index].children.findIndex((item) => {
          return !item.flag
        })
        const len = this.content[index].children.filter((item) => {
          return !item.flag
        }).length
        if (Index === -1) {
          this.content[index].children.forEach((item) => {
            this.$set(item, 'flag', false)
          })
        } else if (len < this.content[index].children.length) {
          this.$set(this.content[index], 'flag', true)
        }
      }
    },
    ToDetail(docid) {
      this.docid = docid
      this.detailpre = true
    },
    giveItem(res) {
      res.map((item) => {
        item.flag = false
        if (item.children) {
          if (item.children.length > 0) {
            this.giveItem(item.children)
          }
          return item
        } else if (item.data && item.data.length > 0) {
          item.children = item.data
          this.giveItem(item.data)
          return item
        } else if (item.child_list && item.child_list.length > 0) {
          item.children = item.child_list
          this.giveItem(item.child_list)
          return item
        }
      })
      return res
    },
    change(...args) {
      let obj = this.content[args[0]]
      let parent = null
      args.splice(0, 1)
      args.forEach((item) => {
        parent = obj
        obj = obj.children[item]
      })
      if (obj.flag) {
        this.$set(parent, 'flag', true)
      }
    },
    jumpToDetail(lawid, catlog) {
      this.lawid = lawid
      this.catlog = catlog || ''
      this.detaillaw = true
    },
    toDetail(docid) {
      this.docid = docid
      this.detailpre = true
    },
    copyText(text) {
      const ipt = document.createElement('input')
      document.body.appendChild(ipt)
      ipt.value = text
      ipt.select()
      document.execCommand('Copy')
      ipt.remove()
      this.$notify({
        type: 'success',
        message: '复制成功',
      })
    },
    copyMore(item) {
      if (this.type === 'LAW_FRAGMENT') {
        // 法律片段
        let text = item.name || item.title
        text += '\t'
        if (!item.children || item.children.length === 0) {
          text += item.text || ''
        } else {
          item.children.forEach((ite, i) => {
            text += `${ite.title || ite.name || ''}\t${ite.text || ''}`
          })
        }
        const ipt = document.createElement('input')
        document.body.appendChild(ipt)
        ipt.value = text
        ipt.select()
        document.execCommand('Copy')
        ipt.remove()
        this.$notify({
          type: 'success',
          message: '复制成功',
        })
      }
    },
    copyAll() {
      let textlog = ''
      if (this.type === 'LEGAL_PRECEDENT_FRAGMENT') {
        // 争议焦点/划选内容
        const num = this.content.findIndex((item) => {
          return item.flag
        })
        if (num === -1) {
          this.$message('没有选中的记录，请选择')
          return false
        }
        this.content.forEach((item, index) => {
          if (item.flag) {
            textlog += item.text
          }
        })
      } else if (this.type === 'LAW_FRAGMENT') {
        // 法律片段
        this.content.forEach((item, index) => {
          textlog += item.name + '\n'
          if (item.children) {
            item.children.forEach((itemr, indexr) => {
              // if (itemr.flag) {
              textlog += `${itemr.title}\t${itemr.text}`
              // }
            })
          }
        })
        this.$nextTick(() => {
          this.showMenuList = false
        })
      }
      console.log(textlog)
      const ipt = document.createElement('input')
      document.body.appendChild(ipt)
      ipt.value += `${textlog}\t`
      ipt.select()
      document.execCommand('Copy')
      ipt.remove()
      this.$notify({
        type: 'success',
        message: '复制成功',
      })
    },
    showDetail(params) {
      let name = ''
      let id = ''
      params.forEach((item) => {
        if (item.split('=')[0] === 'docid' || item.split('=')[0] === 'lawid') {
          name = item.split('=')[0]
          id = item.split('=')[1]
        }
      })
      if (name === 'docid') {
        this.docid = id
        this.detailpre = true
      } else {
        this.lawid = id
        this.detaillaw = true
      }
    },
    async handleShare() {
      let text = window.location.origin + `/preview?userId=${this.userId || this.$route.query.userId}&id=${this.$route.query.id || this.id}`
      const res = await longUrl2ShortUrl(text)
      if (res.status === 200) {
        text = res.data
        const transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = text // 这里表示想要复制的内容
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$notify({
          type: 'success',
          message: '已复制本网页地址，请粘贴到QQ或微信中',
        })
      }
    },

    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    download() {
      const time = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      // console.log('时间戳:', time)
      this.$toast({
        type: 'loading',
        mask: true,
        duration: 0,
        message: '加载中',
        className: 'TC',
      })
      const ua = navigator.userAgent
      if (this.type === 'LINK') {
        let id = ''
        this.content.params.split('&').forEach((item) => {
          if (item.split('=')[0] === 'docid' || item.split('=')[0] === 'lawid') {
            id = item.split('=')[1]
          }
        })

        this.$axios
          .get(`${caseBase}/document/legal/precedent/new/${id}/file`)
          .then((res) => {
            this.$toast.clear()
            console.log(res.data, '====>>> 接口返参10')
            if (ua === 'fb-Android-Webview') {
              // eslint-disable-next-line
              AND2JS.showFilePreview(res.data.data.filePath + res.data.data.fileName)
            } else if (ua === 'fb-iOS-Webview') {
              // ios环境
              // eslint-disable-next-line
              try {
                console.log('ios***********', res.data.data.filePath + res.data.data.fileName)
                window.webkit.messageHandlers.fbDownloadFile.postMessage(res.data.data.filePath + res.data.data.fileName)
              } catch (err) {
                console.log(err, 'ios方法调用失败')
              }
            } else if (ua === 'fb-flutter-Webview') {
              // eslint-disable-next-line
              try {
                // 之前的方法
                // window.fbDownloadFile.postMessage(url)
                // 新改的方法
                window.showFilePreview.postMessage(
                  JSON.stringify({
                    filePath: res.data.data.filePath + res.data.data.fileName,
                    fileName: res.data.data.fileName,
                  })
                )
              } catch (err) {
                console.log('fbDownloadFile.postMessage', err)
              }
            } else {
              console.log(res.data.data)
            }
          })
          .catch((err) => {
            this.$toast.clear()
            this.$toast('分享失败')
            Promise.reject(err)
          })
      } else {
        const data = []
        if (this.type === 'LAW_FRAGMENT') {
          this.content.forEach((item) => {
            let obj = null
            // if (item.flag) {
            obj = {
              text: '',
              name: item.name,
              lawid: item.lawid,
              type: 0,
              children: [],
            }
            // }
            if (item.children) {
              item.children.forEach((iter) => {
                // if (iter.flag) {
                obj.children.push({
                  name: iter.name,
                  text: iter.text,
                  lawid: iter.lawid,
                  type: 4,
                  children: [],
                })
                // }
              })
            }
            if (obj) {
              data.push(obj)
            }
          })
        } else if (this.type === 'LEGAL_PRECEDENT_FRAGMENT') {
          this.content.forEach((item) => {
            if (item.flag) {
              data.push(item)
            }
          })
        }
        this.$axios
          .post(`${caseBase}/document/user/${this.userId || this.$route.query.userId}/fragment`, {
            name: this.title,
            children: data,
          })
          .then((res) => {
            this.$toast.clear()
            console.log(res.data, '====>>> 接口返参11')
            url = res.data.data.path
            if (ua === 'fb-Android-Webview') {
              // eslint-disable-next-line
              AND2JS.showFilePreview(url)
            } else if (ua === 'fb-iOS-Webview') {
              // ios环境
              // eslint-disable-next-line
              try {
                console.log('ios***********', url)
                window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
              } catch (err) {
                console.log(err, 'ios方法调用失败')
              }
            } else if (ua === 'fb-flutter-Webview') {
              // flutter环境
              // eslint-disable-next-line
              try {
                console.log('flutter***********', url)
                // 之前的方法
                // window.fbDownloadFile.postMessage(url)
                // 新改的方法
                window.showFilePreview.postMessage(
                  JSON.stringify({
                    filePath: url,
                    fileName: time,
                  })
                )
              } catch (err) {
                console.log('fbDownloadFile.postMessage', err)
              }
            } else {
              console.log(url)
            }
          })
          .catch((err) => {
            this.$toast.clear()
            this.$toast('分享失败')
            Promise.reject(err)
          })
      }
    },
  },
  created() {},
  mounted() {
    this.isPC = this._browserRedirect

    if (!this.isPC) {
      this.$axios.get(`${caseBase}/management/user/${this.$route.query.userId || this.userId}/collection/${this.$route.query.id || this.id}`).then((res) => {
        this.title = res.data.data.name
        this.type = res.data.data.type
        let $res = null
        if (this.type === 'LAW_FRAGMENT') {
          $res = this.giveItem(JSON.parse(res.data.data.content))
        } else {
          $res = JSON.parse(res.data.data.content)
          if (this.type === 'LEGAL_PRECEDENT_FRAGMENT') {
            $res.map((item) => {
              item.flag = false
              return item
            })
          }
        }
        this.content = $res
        console.log(this.content, 'content')
      })
    } else {
      let rootUrl = 'https://dev.tool.farbun.com'
      if (process.env.SERVER_ENV === 'prod') {
        rootUrl = 'https://tool.farbun.com'
      }
      window.location.href = `${rootUrl}/preview?userId=${this.$route.query.userId || this.userId}&id=${this.$route.query.id || this.id}`
    }
  },
}
</script>

<style lang="stylus" scoped>
html, body
  width 100%
  height 100%
*
  margin 0px
  padding 0px
  list-style none
.preview
  width 100%
  height 100%
  .LEGAL_PRECEDENT_FRAGMENT
    .law-items
      width 100%
      font-size 14px
      margin-bottom 10px
  .link
    li
      font-size 14px
      font-weight 600
  .title
    width 100%
    height 40px
    border-bottom 1px solid #f3f4f7
    font-size 14px
    color #000
    font-weight 400
    line-height 40px
    .icon--
      color #569af0
    ul
      width 98%
      margin 0px auto
      display flex
      li
        padding-left 2%
        height 40px
        box-sizing border-box
      li:nth-child(1)
        width 6%
        text-align center
        padding-left 0
      li:nth-child(2)
        width 70%
        white-space nowrap
        text-overflow ellipsis
        overflow hidden
      li:nth-child(3)
        width 11%
        text-align center
        color #569af0
      li:nth-child(4)
        width 11%
        text-align center
        color #569af0
      & .morelist
        position relative
        & .menu-list
          position absolute
          z-index 10
          right -5px
          top 34px
          width 50px
          box-sizing border-box
          border-radius 3px
          padding 0 5px
          line-height 30px
          background #fff
          box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
          display flex
          flex-direction column
          justify-content center
  .content
    width 95%
    height calc(100% - 40px)
    overflow auto
    padding 10px
    box-sizing border-box
    margin 0px auto
    .law-content
      overflow hidden
      .law-items
        width 100%
        margin-bottom 20px
        .text
          font-size 16px
          font-weight 600
          margin-bottom 10px
          color #34495e
          // display flex
          .text-title
            flex 1
            white-space nowrap
            text-overflow ellipsis
            overflow hidden
          .showicon
            width 80px
            text-align center
            // visibility hidden
          .checkbox
            display inline-block
            vertical-align -6px
            margin-left 10px
        .text:hover
          .showicon
            visibility visible
        .children
          .text
            // text-align center
            font-size 14px
            margin-bottom 15px
            font-weight normal
            // >:nth-child(1)
            //   margin-bottom 10px
            //   color #34495e
            //   font-weight 600
            // :nth-child(2)
            //   box-sizing border-box
            //   padding-left 5px
            //   padding-right 5px
            //   color #444
            .text-content
              box-sizing border-box
              padding-left 5px
              padding-right 5px
              color #444
              .showicon
                // visiblity hidden
            & :hover
              .showicon
                visiblity visible
            // .checkbox
            //   margin-bottom 0px !important
.detail
  width 100%
  height 100%
  background-color white
  position fixed
  top 0px
  left 0px
.showicon svg.ico
  color #666
</style>
