// import axios from 'axios'
/**
 * url: 完整的URL地址，如：https://dev.tool.farbun.com/search?id=124&name=张三
 * expireDate: 超时时间，expireDate>=2040-01-01表示永久有效
 */
const longUrl2ShortUrl = async (url, expireDate = '2040-01-01') => {
  /* const params = {
    domain: '1', // 可选择域名。“0”代表b6i.cn；“1”代表nxw.so；为1的时候，可以在微信中打开
    url: encodeURI(url), // urlencode(‘链接’)
    key: '5e6053e359c077207b0b35d56e@9188405359e5323ece8ab2f29e959dba', // key
    expireDate: expireDate // 若expireDate为空，默认有效期3个月。若expireDate>=2040-01-01,则永久有效
  }
  const res = await axios.get('https://api.3w.cn/api.htm', {
    params: params
  })
  return res */
  return {
    status: 200,
    data: encodeURI(url),
  }
}

export default longUrl2ShortUrl
