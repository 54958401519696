var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preview" }, [
    _c("div", { staticClass: "title" }, [
      _c("ul", [
        _c("li", { staticClass: "left-head", on: { click: _vm.goback } }, [
          this.id
            ? _c("i", { staticClass: "iconfont icon-back" })
            : _c("i", { staticClass: "iconfont icon--" }),
        ]),
        _c("li", { staticClass: "middle-head" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c(
          "li",
          { staticClass: "right-head", on: { click: _vm.handleShare } },
          [_vm._v("分享")]
        ),
        _c("li", { staticClass: "menu-text morelist" }, [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  _vm.showMenuList = !_vm.showMenuList
                },
              },
            },
            [_vm._v("更多")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMenuList,
                  expression: "showMenuList",
                },
              ],
              staticClass: "menu-list",
            },
            [
              _c("span", { on: { click: _vm.copyAll } }, [_vm._v("复制")]),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.download()
                    },
                  },
                },
                [_vm._v("下载")]
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "content" }, [
      _vm.type === "LAW_FRAGMENT"
        ? _c(
            "div",
            { staticClass: "law-content" },
            _vm._l(_vm.content, function (item1, index1) {
              return _c(
                "div",
                { key: index1, staticClass: "law-items" },
                [
                  _c("div", { staticClass: "text" }, [
                    _c(
                      "span",
                      {
                        staticClass: "text-title",
                        on: {
                          click: function ($event) {
                            return _vm.changeShow(item1.showicon, index1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item1.name) + " ")]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item1.showicon,
                            expression: "item1.showicon",
                          },
                        ],
                        staticClass: "showicon",
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-fuzhi",
                          on: {
                            click: function ($event) {
                              return _vm.copyText(item1.name)
                            },
                          },
                        }),
                        _vm._v("    "),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.copyMore(item1)
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "icon",
                                attrs: {
                                  t: "1611822387975",
                                  viewBox: "0 0 1024 1024",
                                  version: "1.1",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  "p-id": "113028",
                                  width: "16",
                                  height: "16",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M725.179728 157.115733H147.461683C68.090305 157.115733 3.487283 221.809778 3.487283 301.283556v578.548622C3.487283 959.305956 68.090305 1024 147.461683 1024H725.179728c79.371378 0 143.9744-64.694044 143.9744-144.167822V301.283556c0-79.485156-64.603022-144.1792-143.9744-144.1792z m79.371377 722.716445a78.836622 78.836622 0 0 1-79.371377 79.485155H147.461683a78.836622 78.836622 0 0 1-79.371378-79.485155V301.283556a78.836622 78.836622 0 0 1 79.371378-79.485156H725.179728a78.836622 78.836622 0 0 1 79.371377 79.485156z m0 0",
                                    "p-id": "113029",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M878.438394 0H250.817417c-44.293689 0-86.744178 22.175289-114.437689 57.298489-11.070578 14.791111-7.384178 35.1232 7.384177 46.216533s35.066311 7.395556 46.136889-7.395555c14.791111-20.332089 36.909511-31.414044 62.759823-31.414045h627.564088c42.461867 0 77.5168 35.111822 77.5168 77.630578v628.440178c0 20.332089-9.227378 40.664178-23.995733 55.455289a34.224356 34.224356 0 0 0-1.831822 46.205155c3.6864 5.552356 12.925156 9.238756 22.152533 9.238756a34.019556 34.019556 0 0 0 22.152534-9.238756c27.682133-25.873067 44.293689-64.694044 44.293688-103.537778v-626.574222A142.279111 142.279111 0 0 0 878.438394 0z m0 0",
                                    "p-id": "113030",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M679.042839 558.216533H468.622217V345.645511a33.223111 33.223111 0 1 0-66.446223 0V556.373333H191.755372a33.268622 33.268622 0 0 0 0 66.537245h210.420622v210.716444a33.223111 33.223111 0 1 0 66.446223 0V622.8992h210.420622a33.109333 33.109333 0 0 0 33.223111-33.268622c0-18.488889-14.791111-31.425422-33.223111-31.425422z m0 0",
                                    "p-id": "113031",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v("    "),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item1.lawid,
                              expression: "item1.lawid",
                            },
                          ],
                          staticClass: "iconfont icon-daochu",
                          on: {
                            click: function ($event) {
                              return _vm.jumpToDetail(item1.lawid)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  !item1.children && item1.text
                    ? _c("div", { staticClass: "children child-text" }, [
                        _vm._v(" " + _vm._s(item1.text) + " "),
                      ])
                    : _vm._e(),
                  _vm._l(item1.children, function (item2, index2) {
                    return _c("div", { key: index2, staticClass: "children" }, [
                      _c("div", { staticClass: "text" }, [
                        _c("div", { staticStyle: { "text-align": "left" } }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeShowChild(
                                    item2.showicon,
                                    index1,
                                    index2
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(item2.name || item2.title))]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item2.showicon,
                                  expression: "item2.showicon",
                                },
                              ],
                              staticClass: "showicon",
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-fuzhi",
                                on: {
                                  click: function ($event) {
                                    return _vm.copyText(item2.title)
                                  },
                                },
                              }),
                              _vm._v("    "),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyMore(item2)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "icon",
                                      attrs: {
                                        t: "1611822387975",
                                        viewBox: "0 0 1024 1024",
                                        version: "1.1",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        "p-id": "113028",
                                        width: "16",
                                        height: "16",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M725.179728 157.115733H147.461683C68.090305 157.115733 3.487283 221.809778 3.487283 301.283556v578.548622C3.487283 959.305956 68.090305 1024 147.461683 1024H725.179728c79.371378 0 143.9744-64.694044 143.9744-144.167822V301.283556c0-79.485156-64.603022-144.1792-143.9744-144.1792z m79.371377 722.716445a78.836622 78.836622 0 0 1-79.371377 79.485155H147.461683a78.836622 78.836622 0 0 1-79.371378-79.485155V301.283556a78.836622 78.836622 0 0 1 79.371378-79.485156H725.179728a78.836622 78.836622 0 0 1 79.371377 79.485156z m0 0",
                                          "p-id": "113029",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d: "M878.438394 0H250.817417c-44.293689 0-86.744178 22.175289-114.437689 57.298489-11.070578 14.791111-7.384178 35.1232 7.384177 46.216533s35.066311 7.395556 46.136889-7.395555c14.791111-20.332089 36.909511-31.414044 62.759823-31.414045h627.564088c42.461867 0 77.5168 35.111822 77.5168 77.630578v628.440178c0 20.332089-9.227378 40.664178-23.995733 55.455289a34.224356 34.224356 0 0 0-1.831822 46.205155c3.6864 5.552356 12.925156 9.238756 22.152533 9.238756a34.019556 34.019556 0 0 0 22.152534-9.238756c27.682133-25.873067 44.293689-64.694044 44.293688-103.537778v-626.574222A142.279111 142.279111 0 0 0 878.438394 0z m0 0",
                                          "p-id": "113030",
                                        },
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d: "M679.042839 558.216533H468.622217V345.645511a33.223111 33.223111 0 1 0-66.446223 0V556.373333H191.755372a33.268622 33.268622 0 0 0 0 66.537245h210.420622v210.716444a33.223111 33.223111 0 1 0 66.446223 0V622.8992h210.420622a33.109333 33.109333 0 0 0 33.223111-33.268622c0-18.488889-14.791111-31.425422-33.223111-31.425422z m0 0",
                                          "p-id": "113031",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v("    "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item1.lawid,
                                    expression: "item1.lawid",
                                  },
                                ],
                                staticClass: "iconfont icon-daochu",
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpToDetail(
                                      item2.lawid,
                                      item2.name
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "text-content" }, [
                          _vm._v(" " + _vm._s(item2.text) + " "),
                        ]),
                      ]),
                    ])
                  }),
                ],
                2
              )
            }),
            0
          )
        : _vm.type === "LEGAL_PRECEDENT_FRAGMENT"
        ? _c(
            "div",
            { staticClass: "LEGAL_PRECEDENT_FRAGMENT" },
            _vm._l(_vm.content, function (item1, index1) {
              return _c("div", { key: index1, staticClass: "law-items" }, [
                _c(
                  "div",
                  { staticClass: "text", staticStyle: { display: "flex" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { width: "90%" },
                        on: {
                          click: function ($event) {
                            return _vm.toDetail(item1.docid)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item1.text) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "checkbox",
                        staticStyle: { width: "5%", "margin-left": "10px" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c("el-checkBox", {
                          model: {
                            value: item1.flag,
                            callback: function ($$v) {
                              _vm.$set(item1, "flag", $$v)
                            },
                            expression: "item1.flag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
    ]),
    _vm.detaillaw
      ? _c(
          "div",
          { staticClass: "detail" },
          [
            _c("law-detail", {
              attrs: { id: _vm.lawid, catlog: _vm.catlog },
              on: {
                changeflag: function ($event) {
                  _vm.detaillaw = false
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.detailpre
      ? _c(
          "div",
          { staticClass: "detail" },
          [
            _c("precedentDetail", {
              attrs: { id: _vm.docid },
              on: {
                changeflag: function ($event) {
                  _vm.detailpre = false
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }